import { ConfigStore } from 'core'

export type Role =
  | 'guest'
  | 'player'
  | 'manager'
  | 'agentplayer'
  | 'masteragent'
  | 'supermasteragent'
  | 'partner'

export type Action =
  | 'seeLoginForm'
  | 'seeRegistrationPage'
  | 'seeBalanceLink'
  | 'seeAccountLink'
  | 'seeAccountPage'
  | 'seeLogoutLink'
  | 'placeBet'

export type Permission = {
  defaultRoute: string
  actions: Action[]
}

export class Permissions {
  constructor(private config: ConfigStore) {}

  private get permissions(): { [role in Role]?: Permission } {
    return (this.config.data.frontConfiguration && this.config.data.frontConfiguration.permissions) || {}
  }

  public can(role: Role, action: Action): boolean {
    const permission = this.permissions[role]
    if (!permission) return false

    return permission.actions.indexOf(action) !== -1
  }

  public getDefaultRoleRoute(role: Role) {
    const rolePermissions = this.permissions[role]
    if (rolePermissions && rolePermissions.defaultRoute) {
      return rolePermissions.defaultRoute
    } else {
      return '/'
    }
  }
}

export const exchange = {
  guest: {
    defaultRoute: '/',
    allowedRoutes: '*',
    deniedRoutes: /^\/account/,
    can: ['seeLoginForm'],
  },
  player: {
    defaultRoute: '/',
    allowedRoutes: '*',
    deniedRoutes: '/registration',
    can: ['placeBet', 'seeAccountLink', 'seeAccountPage', 'seeBalanceLink', 'seeLogoutLink'],
  },
}

export const shop = {
  guest: {
    defaultRoute: '/',
    allowedRoutes: '*',
    deniedRoutes: /^\/account/,
    can: ['seeLoginForm'],
  },
  player: {
    defaultRoute: '/',
    allowedRoutes: '*',
    deniedRoutes: /^\/registration/,
    can: ['placeBet', 'seeAccountLink', 'seeAccountPage', 'seeBalanceLink', 'seeLogoutLink'],
  },
  manager: {
    defaultRoute: '/',
    allowedRoutes: '*',
    deniedRoutes: [/^account/, /^\/registration/],
    can: ['seeBalanceLink', 'seeLogoutLink'],
  },
}

export const sportsbook = {
  guest: {
    defaultRoute: '/',
    allowedRoutes: '*',
    deniedRoutes: /^\/account/,
    can: ['seeLoginForm'],
  },
  player: {
    defaultRoute: '/',
    allowedRoutes: '*',
    deniedRoutes: /^\/registration/,
    can: ['placeBet', 'seeAccountLink', 'seeAccountPage', 'seeBalanceLink', 'seeLogoutLink'],
  },
}
