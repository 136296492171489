import React, { useContext } from 'react'
import cc from 'classcat'

import './mobile.css'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'core/app.store'

export const MobileBgComponent = observer(() => {
  const { mobile } = useContext(AppContext)

  return (
    <div
      onClick={mobile.hideMobileElements}
      className={cc(['mobile__bg', { mobile__bg_show: mobile.mobileBgActive }])}
    />
  )
})
