import { action, computed, observable, runInAction } from 'mobx'
import { HttpStore, I18nStore } from 'core'
import { Language } from 'core/i18n'
import * as storage from 'utils/storage'

export class LanguagesStore {
  @observable list: Language[] = []

  constructor(private http: HttpStore, private i18n: I18nStore) {}

  @computed
  get isReady() {
    return this.list.length > 0 && !!this.i18n.language
  }

  async fetch() {
    const response = await this.http.wst.get('languages')
    runInAction(() => {
      this.list = response.data.map((lang: string) => new Language(lang))
    })
  }

  /** Select current language and reload page */
  @action.bound
  select(code: string): void {
    if (this.i18n.language.longCode === code) return

    storage.set('locale', code)
    this.i18n.updateLanguage(code)
    this.i18n.reloadTranslations(code)
  }
}
