import { observable, computed } from 'mobx'

export interface LocationJSON {
  city: string | null
  country: string | null
  house: string | null
  mobilePhone: string | null
  phone: string | null
  street: string | null
  zip: string | null
}

export class Location {
  @observable city: string | null
  @observable country: string | null
  @observable house: string | null
  @observable mobilePhone: string | null
  @observable phone: string | null
  @observable street: string | null
  @observable zip: string | null

  constructor(location: LocationJSON) {
    this.city = location.city
    this.country = location.country
    this.house = location.house
    this.mobilePhone = location.mobilePhone
    this.phone = location.phone
    this.street = location.street
    this.zip = location.zip
  }

  @computed
  public get address(): string {
    let address = []
    if (this.city) address.push(`City: ${this.city}`)
    if (this.street) address.push(`street: ${this.street}`)
    if (this.zip) address.push(`zip: ${this.zip}`)

    return address.join(', ')
  }
}
