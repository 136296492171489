import { observable, runInAction } from 'mobx'
import { HttpStore, UserStore } from 'core'
import { Group, Games, Provider } from '../casino'
import {ucFirst} from '../../../utils/seo'

export type CasinoFilter = 'popular' | 'new' | 'hot'
interface LoadGamesParams {
  filter?: CasinoFilter
  provider?: string
  group?: string
  page?: number
  pager?: boolean
}
const PER_PAGE = 15
export class DashboardCasinoStore {
  @observable groups: Group[] = []
  @observable providers: Provider[] = []
  @observable favoritesCount: number
  @observable games: Games
  @observable totalGames: number

  constructor(private http: HttpStore, private user: UserStore) {
    this.toggleFavorite = this.toggleFavorite.bind(this)
  }

  async loadGroups(provider?: string) {
    if (this.groups.length) {
      return
    }
    const { data: groups } = await this.http.wst.get<Group[]>('/casino/groups/', {
      params: {
        ...(provider ? { provider } : {}),
      },
    })

    runInAction(() => {
      this.groups = groups
    })
  }

  async loadProviders() {
    if (this.providers.length) {
      return
    }
    const { data: providers } = await this.http.wst.get<Provider[]>('casino/providers/')

    runInAction(() => {
      this.providers = providers
    })
  }

  async loadFavoriteCount() {
    if (!this.user.isLoggedIn) {
      return
    }

    try {
      const { data: favorites } = await this.http.wst.get<Games>('casino/games/favorites')
      runInAction(() => {
        this.favoritesCount = favorites.items.length
        return this.favoritesCount
      })
    } catch (e) {
      console.log(e)
    }
  }

  async loadGames({ provider, group, filter, page, pager = false }: LoadGamesParams) {
    try {
      const params = {
        ...(provider ? { provider } : {}),
        ...(group ? { group } : {}),
        ...(filter ? { filter } : {}),
        page: page || 1,
        per_page: 15,
        pager
      }
      if (pager) {
        params['pager'] = true
      }
      const { data: games } = await this.http.wst.get<Games>('/casino/games/', {
        params,
      })
      games.items = games.items.map(g => {
        g.name = g.name.split('-').map(name => {
          return ucFirst(name)
        }).join(' ')
        return g
      })

      if (page && page !== 1 && !pager) {
        runInAction(() => {
          this.games = { ...games || [], items: this.games ? this.games.items.concat(games.items) : games.items}
        })
      } else {
        runInAction(() => {
          this.games = games
          if (pager) {
            this.totalGames = this.calculatePagesCount(PER_PAGE, games.totalItems)
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  calculatePagesCount(pageSize: number, totalCount: number) {
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize)
  }

  async loadFavoriteGames({ provider, group, filter, page, pager = false }: LoadGamesParams) {
    try {
      const params = {
        ...(provider ? { provider } : {}),
        ...(group ? { group } : {}),
        ...(filter ? { filter } : {}),
        page: page || 1,
        per_page: 15,
        pager
      }
      if (pager) {
        params['pager'] = true
      }
      const { data: games } = await this.http.wst.get<Games>('/casino/games/favorites')
      games.items = games.items.map(g => {
        g.name = g.name.split('-').map(name => {
          return ucFirst(name)
        }).join(' ')
        return g
      })

      if (page && page !== 1 && !pager) {
        runInAction(() => {
          this.games = { ...games || [], items: this.games ? this.games.items.concat(games.items) : games.items}
        })
      } else {
        runInAction(() => {
          this.games = games
          if (pager) {
            this.totalGames = this.calculatePagesCount(PER_PAGE, games.totalItems)
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  async toggleFavorite(gameId: number, isFavorite: boolean) {
    if (!isFavorite) {
      await this.http.wst.get('casino/add_favorite/' + gameId)
      runInAction(() => {
        this.favoritesCount += 1
        this.games.items.find(game => game.id === gameId)!.favorite = true
      })
    } else {
      await this.http.wst.get('casino/remove_favorite/' + gameId)
      runInAction(() => {
        this.favoritesCount -= 1
        this.games.items.find(game => game.id === gameId)!.favorite = false
      })
    }
  }
}
