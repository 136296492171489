import React from 'react'
import { createBrowserHistory } from 'history'
import { ConfigStore } from './config'
import { HttpStore } from './http'
import { WSStore } from './ws'
import { I18nStore } from './i18n'
import { OddsStore } from './odds'
import { UserStore } from './user'
import { NotifierStore } from './notifier'
import { MobileStore } from './mobile'
import { LocationStore } from './location'
import { TransactionsStore } from './transactions-store/transactions.store'

import { LanguagesStore } from 'modules/layout/languages'

import { PromoStore } from 'modules/promo/promo.store'
import { RouterStore, syncHistoryWithStore, SynchronizedHistory } from 'mobx-react-router'
import {ErrorHandler} from './errorHandler/error-handler'
import {AnaliticsService} from '../modules/analitics'
import {LotteryPageStore} from '../modules/millioner/lottery-page.store'
import {DashboardCasinoStore} from '../modules/casino/dashboard/casino.store'

export interface AppStore {
  config: ConfigStore
  history: SynchronizedHistory
  http: HttpStore
  ws: WSStore
  i18n: I18nStore
  mobile: MobileStore
  notifier: NotifierStore
  odds: OddsStore
  user: UserStore
  languages: LanguagesStore
  promo: PromoStore
  project: string
  location: LocationStore,
  errHandler: ErrorHandler
  transactionsStore: TransactionsStore,
  analiticsService: AnaliticsService,
  lotteryPageStore: LotteryPageStore,
  dashBoardCasinoStore: DashboardCasinoStore,
}

export function createStore(config: ConfigStore): AppStore {
  let browserHistory
  if (window.location.origin.includes('real')) {
    browserHistory = createBrowserHistory({basename: '/br'})
  } else {
    browserHistory = createBrowserHistory()
  }
  const routingStore = new RouterStore()
  const history = syncHistoryWithStore(browserHistory, routingStore)
  const http = new HttpStore(config)
  const ws = new WSStore()
  const i18n = new I18nStore(http, config)
  const notifier = new NotifierStore()
  const odds = new OddsStore()
  const user = new UserStore(config, http, history, notifier, ws)
  const mobile = new MobileStore(config, history, user)
  const languages = new LanguagesStore(http, i18n)
  const promo = new PromoStore(http)
  const location = new LocationStore(http)
  const errHandler = new ErrorHandler(config, http, user)
  const transactionsStore = new TransactionsStore(http, notifier)
  const analiticsService = new AnaliticsService(config, user)
  const lotteryPageStore = new LotteryPageStore(http, config, ws, user)
  const dashBoardCasinoStore = new DashboardCasinoStore(http, user)

  return {
    config,
    history,
    http,
    ws,
    i18n,
    mobile,
    notifier,
    odds,
    user,
    languages,
    promo,
    project: config.data.project,
    location,
    errHandler,
    transactionsStore,
    analiticsService,
    lotteryPageStore,
    dashBoardCasinoStore,
  }
}

export const AppContext = React.createContext<AppStore>({} as AppStore)
