export function get(key: string, defaultValue?: any): any {
  return window.localStorage.getItem(key) || defaultValue
}

export function set(key: string, value: any) {
  window.localStorage.setItem(key, value)
}

export function remove(key: string) {
  window.localStorage.removeItem(key)
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

