import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Action } from './permissions'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'core'

interface Props {
  ifCan: Action
  children?: React.ReactNode
}

export const AllowedRoute = observer(({ ifCan, children }: Props) => {
  const { user } = useContext(AppContext)

  if (ifCan && user.can(ifCan) && children) {
    return <>{children}</>
  } else {
    return <Redirect from="/" to={user.defaultRoute} />
  }
})
