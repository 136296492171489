import * as storage from 'utils/storage'
import { action, observable } from 'mobx'

import { american } from './american'
import { decimal } from './decimal'
import { fraction } from './fraction'
import { indian } from './indian'
import { malay } from './malay'

type Odds = 'decimal' | 'american' | 'fractional' | 'indian' | 'malay'

export class OddsStore {
  @observable public odds: Odds = storage.get('oddsFormat')

  private readonly oddsMap = {
    decimal,
    american,
    fractional: fraction,
    indian,
    malay,
  }

  @action.bound
  public update(userOdds: string) {
    this.odds = userOdds as Odds
    storage.set('oddsFormat', this.odds)
  }

  public format(num: string | number) {
    const func = this.oddsMap[this.odds || 'decimal']
    const realNumber = Number(num)

    return func(realNumber)
  }
}
