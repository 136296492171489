import { action, observable } from 'mobx'
import { HttpStore } from 'core'
import { PromoItem } from './promo'

type PromoType = 'left' | 'right' | 'prelive' | 'casino'
type PromoDict = { [type in PromoType]: PromoItem[] }

export class PromoStore {
  @observable
  items: PromoDict = {
    left: [],
    right: [],
    prelive: [],
    casino: [],
  }

  constructor(private http: HttpStore) {}

  getUrl(type: PromoType): string | null {
    switch (type) {
      case 'left':
        return 'application/configuration/left_banner'
      case 'right':
        return 'application/configuration/right_banner'
      case 'prelive':
        return 'application/configuration/banner'
      case 'casino':
        return 'application/configuration/casino_banner'
      default:
        return null
    }
  }

  @action.bound
  async load(type: PromoType) {
    const imageWidth = window.innerWidth
    const banners = await this.fetch(type)
    const bannersWithOptimizedImages = banners
      .filter(item => item.picture !== null)
      .map(item => ({
        ...item,
        picture: item
          .picture!.replace(
            'https://s3.eu-central-1.amazonaws.com/sb-media1/',
            'https://sb-media-dev.imgix.net/'
          )
          .replace(
            'https://s3.eu-central-1.amazonaws.com/sb-media1-prod/',
            'https://24images.twic.pics/'
          )
          .replace('https://s3.eu-central-1.amazonaws.com/vie-media/', 'https://vie-gg.imgix.net/')
          .concat(`?w=${imageWidth}&auto=format`),
      }))
    bannersWithOptimizedImages.sort(() => Math.random() - 0.5)
    this.save(type, bannersWithOptimizedImages)
  }

  @action.bound
  fetch(type: PromoType): Promise<PromoItem[]> {
    const url = this.getUrl(type)
    if (url === null) return Promise.resolve([])

    return this.http.wst
      .get(url)
      .then(resp => resp.data as PromoItem[])
      .catch(() => [])
  }

  @action.bound
  save(type: PromoType, items: PromoItem[]) {
    this.items[type] = items
  }
}
