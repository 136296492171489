import { HttpStore } from 'core'

import { TransactionList, Transaction } from './transactions'
import { createEmptyTransactionList } from './transactions.helper'

export class TransactionsService {
  constructor(private http: HttpStore) {}

  public fetchAll(): Promise<TransactionList> {
    return this.http.wst
      .get<TransactionList>('payment-transactions')
      .then(({ data }) => data)
      .catch(() => createEmptyTransactionList())
  }

  public async fetchOne(id: number): Promise<Transaction | null> {
    try {
      const { data } = await this.http.wst.get<Transaction>('payment-transactions/' + id)
      return data
    } catch (e) {
      return null
    }
  }

  public fetchNextPage(page: number): Promise<TransactionList> {
    return this.http.wst
      .get<TransactionList>('payment-transactions', { params: { page } })
      .then(({ data }) => data)
      .catch(() => createEmptyTransactionList())
  }

  public async cancel(id: number) {
    return this.http.wst.get('payment-transactions/cancel/' + id)
  }
}
