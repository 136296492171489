import { action, computed, observable } from 'mobx'
import { ConfigStore, UserStore } from 'core'
import { SynchronizedHistory } from 'core'

export class MobileStore {
  @observable
  isMobile: boolean = /webos|android|iphone|ipad|ipod|blackberry|windows phone/gi.test(navigator.userAgent)
  @observable windowWidth: number = window.innerWidth
  @observable windowHeight: number = window.innerHeight
  @observable mobileBgActive: boolean = false
  @observable loginFormActive: boolean = false
  @observable accountMenuActive: boolean = false
  @observable betslipActive: boolean = false
  @observable menuActive: boolean = false
  @observable balanceMenuActive: boolean = false

  @computed
  get ltMobileWidth() {
    return this.windowWidth < 768
  }

  @computed
  get mtMobileWidth() {
    return this.windowWidth < 495
  }

  @computed
  get gtMobileWidth() {
    return !this.ltMobileWidth
  }

  @computed
  get ltTabletWidth() {
    return this.windowWidth < 1024
  }

  @computed
  get gtTabletWidth() {
    return !this.ltTabletWidth
  }

  @computed
  get ltWideTabletWidth() {
    return this.windowWidth < 1280
  }

  @computed
  get gtWideTabletWidth() {
    return !this.ltWideTabletWidth
  }

  constructor(private config: ConfigStore, private history: SynchronizedHistory, private user: UserStore) {
    window.addEventListener('resize', this.changeWindowSizes)
  }

  @action.bound
  private changeWindowSizes() {
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
  }

  @action.bound
  public hideMobileElements() {
    this.mobileBgActive = false
    this.loginFormActive = false
    this.accountMenuActive = false
    this.betslipActive = false
    this.menuActive = false
    this.balanceMenuActive = false
  }

  @computed
  public get isBetslipShowed(): boolean {
    const { pathname } = this.history.location
    const configData = this.config.data
    return (
      this.user.isLoggedIn &&
      ((this.ltTabletWidth && (configData.dabba && pathname.includes(configData.dabba.prefixUrl))) ||
        (configData.matchbook && pathname.includes(configData.matchbook.prefixUrl)) ||
        (configData.betfair && pathname.includes(configData.betfair.prefixUrl)) ||
        (configData.selfexchange &&
          configData.selfexchange.sources.some(source => pathname.includes(source.url))) ||
        (!!configData.sportsbook &&
          this.config.front['sportsbook.sources'].some(
            source => pathname.includes(source.live.url) || pathname.includes(source.prelive.url)
          )))
    )
  }

  @action.bound
  public showLoginForm() {
    this.hideMobileElements()
    this.loginFormActive = true
    this.mobileBgActive = true
  }

  @action.bound
  public toggleAccountMenu() {
    const isAccountMenuActive = this.accountMenuActive
    this.hideMobileElements()
    if (!isAccountMenuActive) {
      this.accountMenuActive = true
      this.mobileBgActive = true
    }
  }

  @action.bound
  public toggleBetslip() {
    const isBetslipActive = this.betslipActive

    this.hideMobileElements()
    if (!isBetslipActive) {
      this.betslipActive = true
      this.mobileBgActive = true
    }
  }

  @action.bound
  public toggleMenu() {
    const isMenuActive = this.menuActive
    this.hideMobileElements()
    if (!isMenuActive) {
      this.menuActive = true
      this.mobileBgActive = true
    }
  }

  @action.bound
  public toggleBalanceMenu() {
    const isBalanceMenuActive = this.balanceMenuActive
    this.hideMobileElements()
    if (!isBalanceMenuActive) {
      this.balanceMenuActive = true
      this.mobileBgActive = true
    }
  }
}
