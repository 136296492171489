import {AnaliticsServiceInterface, IEvent} from '../analitics.service'
import {ConfigStore} from '../../../core'

declare const amplitude: any

export class Amplitude implements AnaliticsServiceInterface {
    private userId: string
    private btag: string
    private qtag: string

    constructor(config: ConfigStore) {
        (function(e, t) {// @ts-ignore
            // @ts-ignore
            let n = e.amplitude || {_q: [], _iq: {}}; let r = t.createElement('script')
        ;   r.type = 'text/javascript'
        ;   r.integrity = 'sha384-girahbTbYZ9tT03PWWj0mEVgyxtZoyDF9KVZdL+R53PP5wCY0PiVUKq0jeRlMx9M'
        ;   r.crossOrigin = 'anonymous'; r.async = true
        ;   r.src = 'https://cdn.amplitude.com/libs/amplitude-7.2.1-min.gz.js'
        ;   r.onload = function() {if (!e.amplitude.runQueuedFunctions) {
            console.log('[Amplitude] Error: could not load SDK')}}
        ;   let i = t.getElementsByTagName('script')[0]; i.parentNode.insertBefore(r, i)
        ;   function s(e, t) {e.prototype[t] = function() {
            this._q.push([t].concat(Array.prototype.slice.call(arguments, 0))); return this}}
            let o = function() {this._q = []; return this}
            ;            let a = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset']
            ;            for (let u = 0; u < a.length; u++) {s(o, a[u])}n.Identify = o; let c = function() {this._q = []
                ;                                                                                           return this}
            ;            let l = ['setProductId', 'setQuantity', 'setPrice', 'setRevenueType', 'setEventProperties']
            ;            for (let p = 0; p < l.length; p++) {s(c, l[p])}n.Revenue = c
            ;            let d = ['init', 'logEvent', 'logRevenue', 'setUserId', 'setUserProperties', 'setOptOut', 'setVersionName', 'setDomain', 'setDeviceId', 'enableTracking', 'setGlobalUserProperties', 'identify', 'clearUserProperties', 'setGroup', 'logRevenueV2', 'regenerateDeviceId', 'groupIdentify', 'onInit', 'logEventWithTimestamp', 'logEventWithGroups', 'setSessionId', 'resetSessionId']
            ;            function v(e) {function t(t) {e[t] = function() {
                e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)))}}
                                        for (let n = 0; n < d.length; n++) {t(d[n])}}v(n); n.getInstance = function(e) {
                e = (!e || e.length === 0 ? '$default_instance' : e).toLowerCase()
                ; if (!n._iq.hasOwnProperty(e)) {n._iq[e] = {_q: []}; v(n._iq[e])}return n._iq[e]}
                        // @ts-ignore
            e.amplitude = n})(window, document)

        amplitude.getInstance().init('6f18682725e5cb8412f0f18cc83332c3')
    }

    track(event: IEvent, userId: string, btag: string, callback: ((param: any) => void) | undefined): void {
        if (!this.userId && userId !== '') {
            amplitude.getInstance().setUserId(userId)
        }

        if (!this.btag && btag !== '') {
            const identify = new amplitude.Identify().setOnce('btag', btag)
            amplitude.getInstance().identify(identify)
            this.btag = userId
        }

        amplitude.getInstance().logEvent(event.name, {
            action: event.action
        })
    }
}
