import { observable } from 'mobx'

export interface AccountJSON {
  id: number
  status: string
  type: string
  money: Money
  // Exposure limit
  exposure?: Money
  currentExposure?: Money
  completeInfo?: any
}

export class Account {
  @observable id: number
  @observable money: Money
  @observable exposure: Money

  constructor(data: AccountJSON) {
    this.id = data.id
    this.money = data.money
    if (data.currentExposure) {
      this.exposure = data.currentExposure
    }
  }
}
