import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'core'
import { Action } from 'core/user/permissions'

interface Props {
  children?: React.ReactNode
  ifCan: Action
}
export const Show = observer(({ children, ifCan }: Props) => {
  const { user } = useContext(AppContext)

  if (ifCan && user.can(ifCan) && children) {
    return <>{children}</>
  } else {
    return null
  }
})
