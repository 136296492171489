export interface IRouteParams {
  sportSlug?: string
  contentType?: string
  itemId?: string
  slug?: string
  slug1?: string
  slug2?: string
  slug3?: string
  event_id?: string
  market_id?: string
}

export type ContentType = 'SPORT' | 'CATEGORY' | 'COMPETITION' | 'EVENT' | 'MARKET' | 'RACE'

export interface INavigation {
  root?: Array<INavigationItem>
  path: Array<INavigationItem>
  children: Array<INavigationItem>
  siblings?: Array<INavigationItem>
}

export interface INavigationItem {
  id: number
  name: string
  type: ContentType
  slug: string
  selected?: boolean
  competitionId?: string
  startTime?: string
  marketType?: string
  numberOfWinners?: number
}

export type BetSide = 'back' | 'lay'
export type BetType = 'under' | 'over'
export type ExchangeType = 'betfair' | 'matchbook' | 'selfexchange' | 'dabba' | 'fancy'

// constants

export const BACK = 'back'
export const LAY = 'lay'

export const BET_STATUS_PLACED = 'placed'
export const BET_STATUS_ERROR = 'error'
export const BET_STATUS_ACTIVE = 'active'
export const BET_STATUS_REFUNDED = 'refund'
export const BET_STATUS_NEW = 'new'

export const EXCHANGE_TYPE_BETFAIR = 'betfair'
export const EXCHANGE_TYPE_FANCY = 'fancy'
export const EXCHANGE_TYPE_MATCHBOOK = 'matchbook'
export const EXCHANGE_TYPE_SELFEXCHANGE = 'selfexchange'
export const EXCHANGE_TYPE_DABBA = 'dabba'
