import { observable } from 'mobx'

export interface FrontNotificationJSON {
  user_message_id: string
  text: string
}

export class FrontNotification {
  @observable user_message_id: string
  @observable text: string


  constructor(data: FrontNotificationJSON) {
    this.user_message_id = data.user_message_id
    this.text = data.text
  }
}
