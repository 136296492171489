import * as Sentry from '@sentry/browser'
import {ErrSubscriber} from './error-handler'
import {ConfigStore} from '../config'
import {UserStore} from '../user'

export class SentryHandler implements ErrSubscriber {
  slippedStatuses = [404, 401, 301, 302]
  skippedMsg = [
    'Loading chunk',
    'Cannot read property data'
  ]
  shouldHandle = false
  userSetted = false

  constructor(private user: UserStore) {
    if (process.env.SENTRY_DSN) {
      this.shouldHandle = true
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.NODE_ENV,
        defaultIntegrations: false
      })
    }
  }

  handle(err: any, config: ConfigStore) {
    try {
      if (this.slippedStatuses.includes(err.code)) {
        return
      }
      if (this.skippedMsg.includes(err.message)) {
        return
      }
      if (!this.userSetted && this.user.id) {
        Sentry.setUser({id: this.user.id.toString()})
        this.userSetted = true
      }
      Sentry.captureMessage(`${err.message} | ${err.stack.slice(0, 2000)}`)
    } catch (e) {

    }
  }
}
