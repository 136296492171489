export function american(odd: number): string {
  let fvalue: number
  if (odd < 2) {
    fvalue = 100 / (1 - odd)
  } else {
    if (odd === 2) {
      fvalue = 100
    } else {
      fvalue = 100 * (odd - 1)
    }
  }
  return fvalue.toFixed(0)
}
