import React, { useContext } from 'react'
import { AppContext } from 'core'
import { observer } from 'mobx-react-lite'

export const NotifierComponent = observer(() => {
  const { notifier,i18n } = useContext(AppContext)

  if (!notifier.notifications.length) return null

  return (
    <div className="notifier">
      {notifier.activeNotifications.map((notification, index) => (
        <div
          key={index}
          className={`notifier__message notifier__message_${notification.status}`}
          onMouseEnter={notification.focus}
          onMouseLeave={notification.unfocus}
        >
          {notification.source && <span>{notification.source}: </span>}
          <span dangerouslySetInnerHTML={{__html: i18n.t(notification.text)}}></span>
        </div>
      ))}
    </div>
  )
})
