import {observable, runInAction, computed, action} from 'mobx'

import {HttpStore, NotifierStore} from 'core'

import {TransactionList} from './transactions'
import {TransactionsService} from './transactions.service'
import {createEmptyTransactionList} from './transactions.helper'

export class TransactionsStore {
  @observable isLoading = true
  @observable public list: TransactionList = createEmptyTransactionList()
  @observable public canceled: boolean = false

  private transactionsService: TransactionsService

  constructor(
    private http: HttpStore,
    private notifier: NotifierStore,
  ) {
    this.transactionsService = new TransactionsService(this.http)
  }

  @computed
  public get isCanceled(): boolean {
    return this.canceled
  }

  @action.bound
  public resetCancel() {
    this.canceled = false
  }

  @action.bound
  public cancelDone() {
    this.canceled = true
  }

  @computed
  public get canLoadMore(): boolean {
    return this.list.page < this.list.pages
  }

  @computed
  public get isEmpty(): boolean {
    return this.list.items.length === 0
  }

  @action.bound
  public async loadAll() {
    runInAction(() => {
      this.isLoading = true
    })

    const list = await this.transactionsService.fetchAll()

    runInAction(() => {
      this.list = list
      this.isLoading = false
    })
  }

  @action.bound
  public async loadOne(id: number) {
    const transaction = await this.transactionsService.fetchOne(id)
    if (transaction) {
      const index = this.list.items.findIndex(t => t.id === transaction.id)

      runInAction(() => {
        this.list.items[index] = transaction
      })
    }
  }

  @action.bound
  public async loadNext() {
    runInAction(() => {
      this.isLoading = true
    })

    const nextPageList = await this.transactionsService.fetchNextPage(this.list.page + 1)

    runInAction(() => {
      this.list = {
        items: [...this.list.items, ...nextPageList.items],
        page: nextPageList.page,
        pages: nextPageList.pages,
      }
      this.isLoading = false
    })
  }

  @action.bound
  public async cancel(id: number) {
    try {
      await this.transactionsService.cancel(id)
      this.notifier.success('Transaction successfully cancelled')
    } catch (e) {
      this.notifier.error('Transaction cancelling error')
    } finally {
      await this.loadOne(id)
    }
  }

  @action
  public async cancelnotApprovedWithdrawTransactions() {
    Promise.all([
      ...this.notApprovedWithdrawTransactions.map(transaction => this.transactionsService.cancel(transaction.id)),
    ])
      .then(() => {
        this.cancelDone()
        this.loadAll()
        this.notifier.success('Transactions successfully cancelled')
      })
      .catch(() => {
        this.loadAll()
        this.notifier.error('Transactions cancelling error')
      })
  }

  @computed
  get notApprovedWithdrawTransactions() {
    return this.list.items.filter(item => item.status === '__wait_approve' && item.money.amount < 0)
  }

  @computed
  get notApprovedDeposits() {
    return this.list.items.filter(item => item.status === '__wait_approve' && item.money.amount > 0)
  }
}
