import {Game} from '../modules/casino/casino'
import {NavigationStore} from '../modules/exchange/navigation'
import {ConfigStore} from '../core'

export function checkSeoBot() {
    const botPattern = '(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
    const re = new RegExp(botPattern, 'i')
    const userAgent = navigator.userAgent
    return re.test(userAgent)
}

export const addNoIndex = () => {
    const m = document.createElement('meta')
    m.name = 'robots'
    m.content = 'noindex'
    document.head.appendChild(m)
}
export const getRelLink = () => {
    return location.href.split('?')[0]
}

export const ucFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getExchangeH1 = (navigation: NavigationStore) => {
    let res = ''
    try {
        if (navigation.data.path.length) {
            res = navigation.data.path[navigation.data.path.length - 1].name
        }
    } catch (e) {

    }

    return res
}

export const removeNoIndex = () => {
    document.querySelector('[name=\'robots\']')?.remove()
}

export const gameDescription = (game: Game, config: ConfigStore, isDescr = false) => {
    const isBaccarat = game.name.toLowerCase().includes('baccarat')
    const isPoker = game.name.toUpperCase().includes('poker')
        || game.name.toUpperCase().includes('hold')
        || game.name.toUpperCase().includes('teen')
    const isRoulette = game.name.toUpperCase().includes('roulette')
    const isBlackJack = game.name.toUpperCase().includes('blackjack')
    let pre = ''
    let descr = ''
    switch (true) {
        case isPoker:
            pre = `Play Online Bet on ${game.name} game at ${config.data.project} for real money.
            Live Bet on ${game.name} is a popular straightforward, action-filled card game variant in India what based on luck.
            Try your luck with Bet on ${game.name}, make the best possible hand and win big right now.`

            descr = `Play Online ${game.name} game at ${config.data.project} for real money.
            Live ${game.name} is a popular straightforward, action-filled card game variant in India what based on luck.
            Try your luck with ${game.name}, make the best possible hand and win big right now.`
            break
        case isRoulette:
            pre = `Play ${game.name} Game 🂡 Best ${game.name} Casino ${config.data.project} ⏩ Let's Have Fun Watching The Roulette Wheel`
            descr = `Play ${game.name} game at ${config.data.project} casino for real money.
            Live ${game.name} is one of the most popular gambling games throughout the world.
            Get playing online ${game.name}, watch the fantastic wheel spin and win big right now.`
            break
        case isBaccarat:
            pre = `Online ${game.name} is one of the biggest winning gambling games at ${config.data.project}.
            ${game.name} is an exciting game full of intrigue and luck. Play ${game.name} for real money and try your luck for big winning.`

            descr = `Play ${game.name} game at ${config.data.project} casino for real money. Live ${game.name}
            is one of the most popular gambling games throughout the world. Get playing online ${game.name},
            watch the fantastic wheel spin and win big right now.`
            break
        case isBlackJack:
            pre = `${game.name} Online Game 🂡 Visit ${config.data.project} To Play Online Casino ${game.name}
            For Real Money ⏩ Check Our Online ${game.name} With Huge Bonuses`

            descr = `Play Online ${game.name} at ${config.data.project} for real money and win big 24/7.
            ${game.name} is one of the very oldest and most famous card games in casino history.
            Live ${game.name} famous because of easiness to play and not taking a genius-level intellect or special mathematical skills.`
            break
        default:
            pre = `${game.name} Online Game 🂡 Visit ${config.data.project} To Play Online Casino ${game.name}
            For Real Money ⏩ Check Our Online ${game.name} With Huge Bonuses`

            descr = `Play Online ${game.name} at ${config.data.project} for real money and win big 24/7.
            ${game.name} is one of the very oldest and most famous card games in casino history.
            Live ${game.name} famous because of easiness to play and not taking a genius-level intellect or special mathematical skills.`
            break
    }
    return !isDescr ? pre : descr
}

export const titlePattern = (sportName: string, config: ConfigStore, tournamentName = '', defaultName = '') => {
    let result = ''
    try {
        const patterns = [
            {
                key: /\/live/,
                value: ` %Sport_Name% Live Betting Online | Bet on Live %Sport_Name% Matches at ${config.data.project}`
            },
            {
                key: /\/competition\//,
                value: ` %Sport_Name% %Tournament_Name% Betting & Odds | Bet on %Tournament_Name% `
            },
            {
                key: /\/sportsbook\/[a-z]+$/,
                value: ` %Sport_Name% Betting & Odds | Bet on %Sport_Name% Online at ${config.data.project}`
            },
        ]
        patterns.map((v) => {
            // @ts-ignore
            if (location.pathname.match(new RegExp(v.key))) {
                result = v.value.replace(/%Sport_Name%/g, ucFirst(sportName))
                result = result.replace(/%Tournament_Name%/g, tournamentName)
            }
        })
        if (!result.length) {
            const split = location.pathname.split('/')
            const last = split[split.length - 1]
            if (last === 'competitions') {
                defaultName = `${defaultName} competitions`
            }
            result = defaultName + ' Betting Odds - Bet on Sports Online'
        }
    } catch (e) {
    }

    return result
}
