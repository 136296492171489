import {ConfigStore, HttpStore, UserStore, WSStore} from '../../core'
import {action, observable, runInAction} from 'mobx'

export interface Ticket {
    account: Account
    company: Company
    id: number
    status: 0 | 1 | 2
    viewed: boolean
    code: string
}

export interface Company {
    id: number
    name: string
    title: string
    startDate: string
    endDate: string
    money: {
        currency: string
        amount: number
    }
    status: 0 | 1 | 2
    expired: boolean
    winAmount: number
    winCurrencyName: string
}

export interface Account {
    id: number
}

export class LotteryPageStore {
    @observable tickets: Ticket[] = []
    @observable company: Company
    private subscribed = false

    constructor(
        private http: HttpStore,
        private config: ConfigStore,
        private ws: WSStore,
        private user: UserStore
    ) {

    }

    @action.bound
    getActiveCompany() {
        if (this.company) {
            return new Promise<Company>(() => {return this.company})
        }
        return  this.http.wst.post('/lottery/campaign/get-active').then((data) => {
            this.company = data.data
            return this.company
        })
    }

    @action.bound
    getCompany() {
        return this.company
    }

    @action.bound
    setViewed(ids?: number[]) {
        this.http.wst.post('/lottery/tickets/set-viewed', {})
    }

    @action.bound
    async checkNotViewedTickets() {
        const data = await this.http.wst.get('/lottery/tickets/not-viewed')
        runInAction(() => {
                this.tickets = data.data.items || []
            }
        )
    }

    @action.bound
    getAllTickets() {
        return this.http.wst.get('/lottery/tickets')
    }

    @action.bound
    wsSubscribe() {
        if (this.config.data.millionerEnabled && !this.subscribed) {
            this.ws.subscribeChanel(
                `user:${this.config.data.project}:TicketGetEvent:${this.user.account.id}`,
                (resp: any) => {
                    try {
                        if (resp.data) {
                            runInAction(() => {
                                   this.tickets = resp.data.tickets
                                }
                            )
                        }
                    } catch (err) {
                        console.error(err)
                    }
                })
            this.subscribed = true
        }
    }

    @action.bound
    async clear() {
        runInAction(() => {
                this.tickets = []
            }
        )
    }
}