import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'core/app.store'

interface Props {
  template: string
}

export const T = observer(({ template }: Props) => {
  const { i18n } = useContext(AppContext)
  return <>{i18n!.t(template)}</>
})
