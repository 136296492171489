import { persist, create } from 'mobx-persist'
import { action, computed, observable } from 'mobx'

import { HttpStore } from 'core'
import { parametrize } from 'utils/string'
import * as storage from 'utils/storage'

import { Language } from './language'
import { statics } from './statics'
import { ConfigStore } from '../config'

/* TODO translate errors with parameters */
/* For example, limit errors in betslip */

export class I18nStore {
  @observable public language: Language
  @observable public isStaticsLoaded = false
  @observable private isError = false

  /** Backup statics translations */
  @persist('map') private _savedStatics = observable.map<string, string>({})

  /** Dictionary with translations */
  @observable _dict = observable.map<string, string>({})

  constructor(private http: HttpStore, private config: ConfigStore) {
    const hydrate = create()
    hydrate('translations', this)
  }

  @computed
  get isReady() {
    return this.isStaticsLoaded && !!this.language
  }

  @action
  public async updateLanguage(locale: string) {
    this.language = new Language(locale)
  }

  public async load() {
    let locale = storage.get('locale')
    if (!locale) {
      const resp = await this.http.wst.get('language')
      locale = resp.data.locale as string
    }
    this.updateLanguage(locale)
    return this.loadStatics(locale)
  }

  public async loadStatics(locale: string) {
    try {
      const translations: Dict = await this.fetchTranslations(this.language, statics)
      this.saveStatics(translations)
    } catch (ex) {
    } finally {
      return Promise.resolve()
    }
  }

  public async reloadTranslations(locale: string) {
    try {
      const translations: Dict = await this.fetchTranslations(this.language, Array.from(this._dict.keys()))
      this.saveStatics(translations)
    } catch (ex) {
    } finally {
      return Promise.resolve()
    }
  }

  /** Translate all what you want */
  public t(key?: string, params?: { [param: string]: string }): string {
    if (!key) return ''

    let translation = this._dict.get(key) || key

    if (!params) return translation
    return Object.keys(params).reduce((result, param) => result.replace(param, params[param]), translation)
  }

  translateError(error?: ParametrizedError): string {
    if (!error) return ''

    // Is there a parameter
    if (error.messageTemplate.indexOf('{{') !== -1) {
      return parametrize(this.t(error.messageTemplate), error.parameters)
    }

    return this.t(error.message)
  }

  /** Load translations */
  public async translateKeys(keys: string[]) {
    if (this.isError) return

    const filteredKeys = this.filter(keys)
    if (filteredKeys.length) {
      try {
        const translations = await this.fetchTranslations(this.language, filteredKeys)
        this.append(translations)
      } catch (err) {
        this.isError = true
      }
    }
  }

  private request(locale: string, _json: string[]) {
    const _token = this.config.data.frontConfiguration['translations.token']
    return this.http.i18n.post(locale, { _json, _token })
  }

  /** Fetch translations from darkend */
  private fetchTranslations(lang: Language, keys: string[]): Promise<Dict> {
    return this.request(lang.longCode, keys)
      .then(resp => resp.data as Dict)
      .catch(() =>
        this.filter(keys).reduce<Dict>(
          (dict, key) => ({
            ...dict,
            [key]: this._savedStatics.get(key) || key,
          }),
          {}
        )
      )
  }

  @action
  private saveStatics(translations: Dict) {
    this.append(translations)
    this._savedStatics.merge(translations)
    this.isStaticsLoaded = true
  }

  /** Merge exising translations with newest */
  @action
  private append(dictonary: Dict) {
    this._dict.merge(dictonary)
  }

  /** Leaves only not translated keys */
  private filter(keys: string[]): string[] {
    return keys.filter(key => key && !this.isTranslated(key))
  }

  /** Check if key already translated */
  private isTranslated(key: string): boolean {
    return !!this._dict.get(key)
  }
}
