import React, { useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'core'
import './languages.css'

export const LanguagesComponent = observer(() => {
  const { languages, i18n } = useContext(AppContext)

  useEffect(() => {
    languages.fetch()
  }, [])

  if (!languages.isReady || languages.list.length < 2) return null

  return (
    <div className="control languages">
      <label
        style={{
          backgroundImage: `url('/images/locales/${i18n.language.code}.png')`,
        }}
        className="languages__flag"
        htmlFor="language-select"
      />
      <select
        className="select"
        value={i18n.language.code}
        onChange={event => languages.select(event.target.value)}
        id="language-select"
      >
        {languages.list.map(locale => (
          <option key={locale.code} value={locale.code}>
            {i18n.t('t.' + locale.longCode)}
          </option>
        ))}
      </select>
    </div>
  )
})
