import {ConfigStore} from '../config'
import {SentryHandler} from './sentry-handler'
import {HttpStore} from '../http'
import {UserStore} from '../user'

declare const window: any

export interface ErrSubscriber {
  handle(err: any, config: ConfigStore): void

  shouldHandle: boolean
}

const shouldSilence = (err: any): boolean => {
  return false
}

export class ErrorHandler {
  readonly config: ConfigStore
  readonly user: UserStore
  private subscribers: ErrSubscriber[] = []

  constructor(config: ConfigStore, http: HttpStore, user: UserStore) {
    this.config = config
    this.user = user
    this.initDefaultSubscsribers()
    if (this.subscribers.length) {
      window.onerror = this.handleWindowErr.bind(this)
    }
  }

  private initDefaultSubscsribers() {
    const sentry = new SentryHandler(this.user)
    if (sentry.shouldHandle) {
      this.subscribers.push(sentry)
    }
  }

  addSubscriber(subscriber: ErrSubscriber) {
    this.subscribers.push(subscriber)
  }

  handleWindowErr(msg: string, url: string, line: number, column: number, err: Error) {
    if (shouldSilence(err)) {
      return
    }
    this.processSubscribers(err)
  }

  private processSubscribers(err: any) {
    this.subscribers.filter(subscriber => subscriber.shouldHandle).forEach(subscriber => subscriber.handle(err, this.config))
  }
}
