import { observable } from 'mobx'

export interface SettingsJSON {
  oddsFormat?: OddFormat
  sessionDuration?: string
  showAllPayments?: boolean
}

export type OddFormat = '__odd_format_europe' | '__odd_format_uk' | '__odd_format_us'

export class Settings {
  @observable oddFormat: OddFormat
  @observable sessionDuration: number
  showAllPayments?: boolean

  constructor(settings: SettingsJSON) {
    this.oddFormat = settings.oddsFormat || '__odd_format_europe'
    this.sessionDuration = settings.sessionDuration ? parseInt(settings.sessionDuration) : 0
    this.showAllPayments = settings.showAllPayments
  }
}
