export function fraction(odd: number): string {
  let x = 1
  let p = Math.round(odd * x)
  odd = Math.round((odd - 1) * 10000) / 10000
  while (Math.abs(odd * x - p) > 0.009) {
    x += 1
    p = Math.round(odd * x)
  }
  if (x !== 1 && p !== x) {
    return p + '/' + x
  } else {
    return p.toString()
  }
}
