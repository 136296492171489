import {AnaliticsServiceInterface, IEvent} from '../analitics.service'
import {ConfigStore} from '../../../core'

declare const ga: any

export class Google implements AnaliticsServiceInterface {
    constructor(config: ConfigStore) {}

    track(event: IEvent, userId: string, btag: string, callback: (param?: any) => void): void {
        if (typeof ga === 'undefined') {
            return
        }
        ga('set', 'userId', userId)
        ga('send', 'event', event.name, event.action)
    }
}