import { action, observable } from 'mobx'
import { HttpStore } from 'core'
import { countries } from './countries'

export class LocationStore {
  @observable countryCode: string
  @observable countryName: string
  @observable isLoaded: boolean = false

  constructor(private http: HttpStore) {}

  public load() {
    if (!this.isLoaded) {
      // this.http.wst.get('location').then(resp => this.setLocation(resp.data))
    }
  }

  @action
  setLocation(countryCode: string) {
    this.isLoaded = true
    countryCode = countryCode.replace('"', '').toLowerCase()
    this.countryCode = countryCode
    this.countryName = countries[countryCode] ? countries[countryCode].name : ''
  }
}
